/* eslint no-unused-vars: 0 */ // --> OFF
import React, { Component } from "react";
import Parallax from "parallax-js";
import "./Scene.scss";
import timeLapse from "./img/timelapse.mp4";

class Scene extends Component {
  constructor(props) {
    super(props);
    this.sceneRef = React.createRef();
  }

  componentDidMount() {
    this.parallax = new Parallax(this.sceneRef.current, { limitY: 100 });
  }

  componentWillUnmount() {
    this.parallax.destroy();
    this.parallax = null;
  }

  render() {
    return (
      <div className="scene" id="scene" data-relative-input="true" ref={this.sceneRef}>
        <div className="scene-layer scene__background" data-depth="0.00">
          <video autoPlay loop muted className="scene__video">
            <source src={timeLapse} type="video/mp4" />
          </video>
        </div>
        <div className="scene-layer" data-depth="0.03">
          <div className="scene__clouds2" />
        </div>
        <div className="scene-layer" data-depth="0.06">
          <div className="scene__mountains" />
        </div>
        <div className="scene-layer" data-depth="0.10">
          <div className="scene__logo" />
        </div>
        <div className="scene-layer" data-depth="0.12">
          <div className="scene__mountains2" />
        </div>
        <div className="scene-layer" data-depth="0.17">
          <div className="scene__clouds1" />
        </div>
        <div className="scene-layer" data-depth="0.20">
          <div className="scene__paul" />
        </div>
        <div className="scene-layer" data-depth="0.30">
          <div className="scene__ground" />
        </div>
      </div>
    );
  }
}

export default Scene;
