import React, { Component } from "react";
import "./App.scss";
import Loader from "./Loader/Loader";
import Scene from "./Scene/Scene";

class App extends Component {
  state = {
    scenePaulJonah: true,
    loaded: false,
  };

  componentDidMount() {
    window.onload = this.onLoad;
  }

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;
    return (
      <div className="App">
        <Loader loaded={loaded} />

        <Scene />
      </div>
    );
  }
}

export default App;
