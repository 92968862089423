import React from "react";
import "./Loader.scss";

const Loader = ({ loaded }) => {
  return (
    <div className={`preloader ${loaded ? " preloader--loaded" : ""}`}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Loader;
